import { theme } from "antd";

const Container = ({ style, noPadding, children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <div
      style={{
        background: colorBgContainer,
        padding: noPadding ? 0 : 16,
        marginBottom: 16,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
