import Container from "../components/Container/Container";
import PageHeader from "../components/PageHeader/PageHeader";

export default function Home() {
  return (
    <Container>
      <PageHeader title={"Home"} />
    </Container>
  );
}
