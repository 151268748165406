import { Space, Typography } from "antd";
import styles from "./PageHeader.module.scss";

const { Title } = Typography;

const PageHeader = ({ title, extras }) => {
  return (
    <div className={styles.container}>
      <Title style={{ marginBottom: 0, flexGrow: 1 }} level={3}>
        {title}
      </Title>
      {extras && <Space>{extras}</Space>}
    </div>
  );
};

export default PageHeader;
